const userContext = {
  namespaced: true,
  state: {
    customers: null,
    currentCustomer: null,
    tenants: [],
    currentTenant: null,
    rights: {},
    fullName: null,
    user: null,
    nextUser: null,
  },
  mutations: {
    currentCustomer(state, newState) {
      state.currentCustomer = newState;
    },

    customers(state, newState) {
      state.customers = newState;
    },

    currentTenant(state, newState) {
      state.currentTenant = newState;
    },

    tenants(state, newState) {
      state.tenants = newState;
    },

    rights(state, newState) {
      state.rights = newState;
    },

    fullName(state, newState) {
      state.fullName = newState;
    },

    user(state, newState) {
      state.user = newState;
    },

    nextUser(state, newState) {
      state.nextUser = newState;
    },
  },
  actions: {
    updateCurrentCustomer({ commit, state }, newCustomer) {
      return new Promise((resolve, reject) => {
        if (newCustomer) {
          commit("currentCustomer", newCustomer);
          resolve(state.currentCustomer);
        } else {
          reject();
        }
      });
    },

    updateCurrentTenant({ commit, state }, newTenant) {
      return new Promise((resolve, reject) => {
        if (newTenant) {
          commit("currentTenant", newTenant);
          resolve(state.currentTenant);
        } else {
          reject();
        }
      });
    },

    updateRights({ commit, state }, newRights) {
      return new Promise((resolve, reject) => {
        if (newRights) {
          commit("rights", newRights);
          resolve(state.rights);
        } else {
          reject("Error updateRights.");
        }
      });
    },

    updateTenants({ commit, state }, newTenants) {
      return new Promise((resolve, reject) => {
        if (Array.isArray(newTenants)) {
          commit("tenants", newTenants);
          resolve(state.tenants);
        } else {
          reject("Object must be an Array.");
        }
      });
    },
  },
};

export default userContext;
