import { defaultModules, info, success, error } from "@pnotify/core";
import "@pnotify/core/dist/PNotify.css";
import * as PNotifyBootstrap4 from "@pnotify/bootstrap4";
import * as PNotifyFontAwesome5Fix from "@pnotify/font-awesome5-fix";
import * as PNotifyFontAwesome5 from "@pnotify/font-awesome5";

class Notification {
  constructor() {
    defaultModules.set(PNotifyBootstrap4, {});
    defaultModules.set(PNotifyFontAwesome5Fix, {});
    defaultModules.set(PNotifyFontAwesome5, {});
    defaultModules.delay = 5000;
  }

  notifyInfo(title, message) {
    info({
      title: title,
      text: message,
    });
  }

  notifySuccess(title, message) {
    success({
      title: title,
      text: message,
    });
  }

  notifyError(title, message) {
    error({
      title: title,
      text: message,
    });
  }
}

export default new Notification();
