<template>
  <aside class="left-sidebar">
    <!-- Sidebar scroll-->
    <div class="scroll-sidebar">
      <!-- Sidebar navigation-->
      <nav class="sidebar-nav">
        <img src="/img/logo_Next.png" alt="Next" class="logo" />
        <ul id="sidebarnav">
          <!-- SE CONNECTER -->
          <li class="sidebar-item" v-if="!nextUser">
            <router-link to="/Home" class="sidebar-link">
              <i class="fa fa-power-off fa-2x"></i>
              <span class="hide-menu">Connexion</span>
            </router-link>
          </li>
          <!-- HOME -->
          <li class="sidebar-item" v-if="nextUser">
            <router-link to="/Home" class="sidebar-link" active-class="active">
              <i class="fas fa-home fa-2x"></i>
              <span class="hide-menu">Accueil</span>
            </router-link>
          </li>
          <!-- SUPPLIER ORDER -->
          <li class="sidebar-item" v-if="nextUser && rights['Order.Web']">
            <a class="sidebar-link has-arrow" href="javascript:void(0)" aria-expanded="false">
              <i class="fas fa-industry fa-2x"></i>
              <span class="hide-menu">Approvisionnement</span>
            </a>
            <ul aria-expanded="false" class="collapse first-level">
              <li class="sidebar-item">
                <router-link to="/Order/ProductSupplier" class="sidebar-link" active-class="active">
                  <i class="fas fa-cart-arrow-down"></i>
                  <span class="hide-menu">Commander</span>
                </router-link>
              </li>
              <li class="sidebar-item">
                <router-link to="/Order/OrderSupplier" class="sidebar-link" active-class="active">
                  <i class="fas fa-shopping-cart"></i>
                  <span class="hide-menu">Mes commandes</span>
                </router-link>
              </li>
            </ul>
          </li>
          <!-- STOCK -->
          <li class="sidebar-item" v-if="nextUser && (rights['Stock.Web'] || rights['Inventory'])">
            <a class="sidebar-link has-arrow" href="javascript:void(0)" aria-expanded="false">
              <i class="fas fa-warehouse fa-2x"></i>
              <span class="hide-menu">Entreposage</span>
            </a>
            <ul aria-expanded="false" class="collapse first-level">
              <li class="sidebar-item" v-if="nextUser && rights['Stock.Web']">
                <router-link to="/Stock/ReceptionMobile" class="sidebar-link" active-class="active" v-if="isMobile">
                  <i class="fas fa-sign-in-alt"></i>
                  <span class="hide-menu">Réception de marchandises</span>
                </router-link>
                <router-link to="/Stock/Reception" class="sidebar-link" active-class="active" v-else>
                  <i class="fas fa-sign-in-alt"></i>
                  <span class="hide-menu">Réception de marchandises</span>
                  <span
                    class="badge badge-pill badge-warning up rounded-circle badge-menu badge-Reception"
                    style="color: #212529; display: none"
                    >1</span
                  >
                </router-link>
              </li>
              <li class="sidebar-item" v-if="nextUser && rights['Stock.Web']">
                <router-link to="/Stock/Stock" class="sidebar-link" active-class="active">
                  <i class="fas fa-layer-group"></i>
                  <span class="hide-menu">Gestion du stock</span>
                </router-link>
              </li>
              <li class="sidebar-item" v-if="nextUser && rights['Stock.Web']">
                <router-link
                  to="/Stock/PreparationMobile"
                  class="sidebar-link"
                  active-class="active"
                  v-if="isMobile && currentTenant != 'leclerc'"
                >
                  <i class="fas fa-dolly"></i>
                  <span class="hide-menu">Préparation de commande</span>
                </router-link>
                <router-link to="/Stock/Preparation" class="sidebar-link" active-class="active" v-else>
                  <i class="fas fa-dolly"></i>
                  <span class="hide-menu">Préparation de commande</span>
                </router-link>
              </li>
              <li class="sidebar-item" v-if="nextUser && rights['Stock.Web']">
                <router-link to="/Stock/AddressTransfert" class="sidebar-link" active-class="active">
                  <i class="fas fa-people-carry"></i>
                  <span class="hide-menu">Transfert d'adresse</span>
                </router-link>
              </li>
              <li class="sidebar-item" v-if="nextUser && rights['Stock.Web']">
                <router-link to="/Stock/Regularisation" class="sidebar-link" active-class="active">
                  <i class="fas fa-box-open"></i>
                  <span class="hide-menu">Correction de stock</span>
                </router-link>
              </li>
              <li class="sidebar-item" v-if="nextUser && rights['Inventory']">
                <router-link to="/Stock/Inventory" class="sidebar-link" active-class="active">
                  <i class="fas fa-clipboard-list"></i>
                  <span class="hide-menu">Inventaire</span>
                </router-link>
              </li>
              <li class="sidebar-item" v-if="nextUser && rights['Stock.Web']">
                <router-link to="/Stock/Location" class="sidebar-link" active-class="active">
                  <i class="fas fa-map-marker-alt"></i>
                  <span class="hide-menu">Emplacement</span>
                </router-link>
              </li>
              <li class="sidebar-item" v-if="nextUser && rights['Stock.Web']">
                <router-link to="/Stock/Location2" class="sidebar-link" active-class="active" v-if="!isProduction">
                  <v-badge :value="true" color="red" dot>
                    <v-tooltip right>
                      <template v-slot:activator="{ on, attrs }">
                        <i class="fas fa-map"></i>
                        <span class="hide-menu" v-bind="attrs" v-on="on">Supervision</span>
                      </template>
                      <span>BETA</span>
                    </v-tooltip>
                  </v-badge>
                </router-link>
              </li>
            </ul>
          </li>
          <!-- ORDER -->
          <li class="sidebar-item" v-if="nextUser && rights['Order.Web']">
            <a class="sidebar-link has-arrow" href="javascript:void(0)" aria-expanded="false">
              <i class="fas fa-shopping-basket fa-2x"></i>
              <span class="hide-menu">Commande</span>
            </a>
            <ul aria-expanded="false" class="collapse first-level">
              <li class="sidebar-item">
                <router-link to="/Order/Product" class="sidebar-link" active-class="active">
                  <i class="fas fa-cart-arrow-down"></i>
                  <span class="hide-menu">Commander</span>
                </router-link>
              </li>
              <li class="sidebar-item">
                <router-link to="/Order/Order" class="sidebar-link" active-class="active">
                  <i class="fas fa-shopping-cart"></i>
                  <span class="hide-menu">Liste des commandes</span>
                </router-link>
              </li>
            </ul>
          </li>
          <!-- DELIVERY -->
          <li class="sidebar-item" v-if="nextUser && (rights['Delivery.Web'] || rights['Shipment'])">
            <a class="sidebar-link has-arrow" href="javascript:void(0)" aria-expanded="false">
              <i class="fas fa-truck fa-2x"></i>
              <span class="hide-menu">Transport</span>
            </a>
            <ul aria-expanded="false" class="collapse first-level">
              <li class="sidebar-item" v-if="nextUser && rights['Shipment']">
                <router-link to="/Delivery/Shipment" class="sidebar-link" active-class="active">
                  <i class="fas fa-truck"></i>
                  <span class="hide-menu">Expédition</span>
                </router-link>
              </li>
              <li class="sidebar-item" v-if="nextUser && rights['Delivery.Web']">
                <router-link to="/Delivery" class="sidebar-link" active-class="active">
                  <i class="fas fa-truck-loading"></i>
                  <span class="hide-menu">Livraison</span>
                </router-link>
              </li>
            </ul>
          </li>
          <!-- DOCUMENT -->
          <li class="sidebar-item" v-if="nextUser && rights['Document.Web']">
            <a class="sidebar-link has-arrow" href="javascript:void(0)" aria-expanded="false">
              <i class="far fa-file-alt fa-2x"></i>
              <span class="hide-menu">Document</span>
            </a>
            <ul aria-expanded="false" class="collapse first-level">
              <li class="sidebar-item">
                <router-link to="/Document/Document" class="sidebar-link" active-class="active">
                  <i class="far fa-file-alt"></i>
                  <span class="hide-menu">Document</span>
                </router-link>
              </li>
              <li class="sidebar-item" v-if="nextUser.isSuperAdmin">
                <router-link to="/Document/Printing" class="sidebar-link" active-class="active">
                  <i class="fas fa-print"></i>
                  <span class="hide-menu">Impression</span>
                </router-link>
              </li>
              <li class="sidebar-item">
                <router-link to="/Document/Printer" class="sidebar-link" active-class="active">
                  <i class="fas fa-print"></i>
                  <span class="hide-menu">Imprimante</span>
                </router-link>
              </li>
            </ul>
          </li>
          <!-- REPOSITORY -->
          <li class="sidebar-item" v-if="nextUser && (rights['Repository.Web'] || rights['User.Web'])">
            <a class="sidebar-link has-arrow" href="javascript:void(0)" aria-expanded="false">
              <i class="fas fa-cog fa-2x"></i>
              <span class="hide-menu">Paramétrage</span>
            </a>
            <ul aria-expanded="false" class="collapse first-level">
              <li class="sidebar-item" v-if="rights['Repository.Web']">
                <router-link to="/Repository/Product" class="sidebar-link" active-class="active">
                  <i class="fa fa-box-open"></i>
                  <span class="hide-menu">Référentiel produit</span>
                </router-link>
              </li>
              <li class="sidebar-item" v-if="rights['Repository.Web']">
                <router-link to="/Repository/ProductFamily" class="sidebar-link" active-class="active">
                  <i class="fa fa-boxes"></i>
                  <span class="hide-menu">Famille de produit</span>
                </router-link>
              </li>
              <li class="sidebar-item" v-if="rights['Repository.Web']">
                <router-link to="/Repository/Attributes" class="sidebar-link" active-class="active">
                  <i class="fas fa-tag"></i>
                  <span class="hide-menu">Attributs</span>
                </router-link>
              </li>
              <li class="sidebar-item" v-if="rights['Repository.Web']">
                <router-link to="/Repository/GroupAttributes" class="sidebar-link" active-class="active">
                  <i class="fas fa-tags"></i>
                  <span class="hide-menu">Groupes d'attributs</span>
                </router-link>
              </li>
              <li class="sidebar-item" v-if="rights['Repository.Web']">
                <router-link to="/Repository/Company" class="sidebar-link" active-class="active">
                  <i class="fas fa-city"></i>
                  <span class="hide-menu">Référentiel tiers</span>
                </router-link>
              </li>
              <li class="sidebar-item" v-if="rights['User.Web']">
                <router-link to="/Admin/User" class="sidebar-link" active-class="active">
                  <i class="fas fa-user-friends"></i>
                  <span class="hide-menu">Utilisateur</span>
                </router-link>
              </li>
              <!--<li class="sidebar-item" v-if="rights['Repository.Web']">
                <router-link to="/Repository/LocationTypes" class="sidebar-link">
                  <i class="fas fa-map-marker-alt"></i>
                  <span class="hide-menu">Type d'emplacement</span>
                </router-link>
              </li>
              <li class="sidebar-item" v-if="rights['Repository.Web']">
                <router-link to="/Repository/SupportTypes" class="sidebar-link">
                  <i class="fas fa-pallet"></i>
                  <span class="hide-menu">Type de support</span>
                </router-link>
              </li>
              <li class="sidebar-item" v-if="rights['Repository.Web']">
                <router-link to="/Repository/ValueTypes" class="sidebar-link">
                  <i class="fas fa-tag"></i>
                  <span class="hide-menu">Type de valeur</span>
                </router-link>
              </li>
              <li class="sidebar-item" v-if="rights['Repository.Web']">
                <router-link to="/Repository/Unit" class="sidebar-link">
                  <i class="fas fa-tag"></i>
                  <span class="hide-menu">Unité</span>
                </router-link>
              </li>-->
            </ul>
          </li>
          <!-- HELP -->
          <li class="sidebar-item">
            <a class="sidebar-link has-arrow" href="javascript:void(0)" aria-expanded="false">
              <i class="fas fa-question-circle fa-2x"></i>
              <span class="hide-menu">Aide</span>
            </a>
            <ul aria-expanded="false" class="collapse first-level">
              <li class="sidebar-item">
                <router-link to="/Contact" class="sidebar-link" active-class="active">
                  <i class="fas fa-headset"></i>
                  <span class="hide-menu">Nous contacter</span>
                </router-link>
              </li>
            </ul>
          </li>
          <!-- PROFILE -->
          <li class="sidebar-item" v-if="nextUser">
            <router-link to="/Profile" class="sidebar-link" active-class="active">
              <avatar class="avatar" :username="fullname" color="white" :size="40"></avatar>
              <span class="hide-menu" style="margin-left: 10px">Mon profil</span>
            </router-link>
          </li>
          <!-- INFOS -->
          <li class="sidebar-item" v-if="nextUser && (!isProduction || nextUser.isSuperAdmin)">
            <a class="sidebar-link has-arrow" href="javascript:void(0)" aria-expanded="false">
              <i class="fas fa-flag" :style="`font-size: 1.5em !important; color: ${getEnvironmentColor()};`"></i>
              <span class="hide-menu text-uppercase">{{ environment }}</span>
            </a>
            <ul aria-expanded="false" class="collapse first-level">
              <li class="sidebar-item">
                <router-link to="/Admin/Status" class="sidebar-link" active-class="active">
                  <i class="far fa-check-circle"></i>
                  <span class="hide-menu">Status</span>
                </router-link>
              </li>
              <li class="sidebar-item">
                <router-link to="/Admin/BugStatus" class="sidebar-link" active-class="active">
                  <i class="fas fa-bug"></i>
                  <span class="hide-menu">BugStatus</span>
                </router-link>
              </li>
            </ul>
          </li>
        </ul>
      </nav>
      <!-- End Sidebar navigation -->
    </div>
    <!-- End Sidebar scroll-->
  </aside>
</template>

<style lang="scss" scoped>
aside.left-sidebar {
  position: fixed;
}
</style>

<script>
import SuperMenu from "@/app/shared/js/supermenu.js";
import LocalStorage from "@/app/shared/js/localstorage.js";
import Avatar from "vue-avatar";
import { mapActions, mapState } from "vuex";
import { environment } from "@/environment/environment";
import ScreenHelper from "@/app/shared/services/helpers/ScreenHelper";

export default {
  name: "app-menu",

  components: {
    Avatar,
  },

  data() {
    return {
      fullname: this.$keycloak.fullName,
      year: new Date().getFullYear(),
      isPushEnabled: false,
    };
  },

  computed: {
    ...mapState("userContext", ["currentTenant", "rights", "tenants", "nextUser"]),

    environment: function () {
      return environment;
    },

    isProduction: function () {
      return environment === "production";
    },

    isMobile: function () {
      return ScreenHelper.isMobile;
    },
  },

  methods: {
    ...mapActions("userContext", ["updateCurrentTenant"]),

    getEnvironmentColor: function () {
      switch (this.environment) {
        case "development":
          return "#3fe9b7";
        case "staging":
          return "#623fe9";
        case "demo":
          return "#c6e93f";
        case "preprod":
          return "#ff3e69";
        default:
          return "#ffffff";
      }
    },
  },

  mounted() {
    const supernavbarScript = document.createElement("script");
    supernavbarScript.setAttribute("src", "/js/supernavbar.js");
    document.body.appendChild(supernavbarScript);

    const autoscrollScript = document.createElement("script");
    autoscrollScript.setAttribute("src", "/js/autoscroll.js");
    document.body.appendChild(autoscrollScript);

    const contextmenuScript = document.createElement("script");
    contextmenuScript.setAttribute("src", "/js/contextmenu.js");
    document.body.appendChild(contextmenuScript);

    SuperMenu.initializeSupermenu();
    LocalStorage.displayInfoLocalStorage();
  },
};
</script>
