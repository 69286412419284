const featuresFlipping = {
  namespaced: true,
  state: {
    printAuthorise: null,
  },
  mutations: {
    printAuthorise(state, newState) {
      state.printAuthorise = newState;
    },
  },
  actions: {},
};

export default featuresFlipping;
