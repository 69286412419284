/* eslint-disable no-unused-vars */
import Vue from "vue";
import store from "@/app/app-state";
import axios from "axios";
import { cacheAdapterEnhancer } from "axios-extensions";

class RestClient {
  constructor(baseUrl) {
    this.RestClientLoadingCount = 0;

    this.handleError = true;
    let notimeout = true;
    let timeout = notimeout ? 0 : 2500;

    this.Instance = axios.create({
      timeout: timeout,
      baseURL: baseUrl,
      crossDomain: true,
      // disable the default cache
      adapter: cacheAdapterEnhancer(axios.defaults.adapter, { enabledByDefault: false }),
    });

    var self = this;

    this.Instance.interceptors.request.use(
      function (config) {
        //Add some headers on each request
        config.headers = {
          Authorization: "Bearer " + Vue.prototype.$keycloak.token,
          "Cache-Control": "no-cache",
          "X-Next-CustomerCode": store.state.userContext.currentCustomer,
          "X-Next-TenantCode": store.state.userContext.currentTenant,
        };
        self.RestClientLoadingCount += 1;
        window.postMessage({ type: "loading", value: self.RestClientLoadingCount });

        return config;
      },
      function (error) {
        // Pull config, status and data from the error
        const { config } = error;

        if (self.handleError) {
          //Notification.notifyError('Remote Connection', `${config.url}: ${error.message}`)
          console.log("Remote Connection " + `${config.url}: ${error.message}`);
          return Promise.reject(error);
        }
        return config;
      }
    );

    this.Instance.interceptors.response.use(
      function (response) {
        self.RestClientLoadingCount -= 1;
        window.postMessage({ type: "loading", value: self.RestClientLoadingCount });
        return response;
      },
      function (error) {
        self.RestClientLoadingCount -= 1;
        window.postMessage({ type: "loading", value: self.RestClientLoadingCount });
        // Pull config, status and data from the error
        const { config, response } = error;
        //Si on est en 409, on ne veux pas de message, on le gère par une modale Oui/Non
        if (self.handleError === true && response.status !== 409) {
          //Notification.notifyError('Remote Connection', `${config.url}: ${error.message}`)
          console.log("Remote Connection " + `${config.url}: ${error.message}`);
          return Promise.reject(error).then(
            function () {},
            function (error2) {
              return response;
            }
          );
        }
        return response;
      }
    );
  }
}

export default RestClient;
