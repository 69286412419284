import store from "@/app/app-state";

const deliveryRoutes = [
  {
    name: "Delivery.Web",
    path: "/Delivery",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Transport",
        },
        {
          text: "Livraison",
          to: "/Delivery",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "deliveryComponents" */ "./delivery/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Shipment",
    path: "/Delivery/Shipment",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Transport",
        },
        {
          text: "Expédition",
          to: "/Shipment",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "shipmentComponents" */ "./shipment/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
];

export default deliveryRoutes;
