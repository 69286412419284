var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", { staticClass: "topbar" }, [
    _vm._m(0),
    _c(
      "nav",
      { staticClass: "navbar top-navbar navbar-expand-md navbar-dark bg-dark" },
      [
        _c(
          "div",
          { staticClass: "navbar-header" },
          [
            _vm._m(1),
            _c(
              "b-nav-item",
              {
                staticClass: "displayTP page-breadcrumb bold mr-auto",
                attrs: { disabled: "" },
              },
              [
                _c("b-breadcrumb", {
                  staticClass: "breadcrumb",
                  attrs: { items: _vm.items, divider: "/" },
                }),
              ],
              1
            ),
            _vm.nextUser
              ? _c(
                  "v-btn",
                  {
                    staticClass:
                      "nav-link after-none notification-button ml-auto",
                    attrs: { dark: "", icon: "" },
                    on: { click: _vm.togglePush },
                  },
                  [
                    _c("v-icon", { attrs: { dark: "", medium: "" } }, [
                      _vm._v(
                        _vm._s(
                          _vm.isPushEnabled
                            ? "notifications"
                            : "notifications_off"
                        )
                      ),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
            _vm.nextUser && _vm.logout
              ? _c(
                  "v-btn",
                  {
                    staticClass: "nav-link after-none",
                    attrs: { dark: "", icon: "" },
                    on: { click: _vm.logout },
                  },
                  [_c("i", { staticClass: "fas fa-power-off" })]
                )
              : _vm._e(),
          ],
          1
        ),
        _c(
          "div",
          {
            staticClass: "navbar-collapse collapse",
            attrs: { id: "navbarSupportedContent" },
          },
          [
            _vm._m(2),
            _c("div", { staticClass: "navbar-nav float-left mr-auto ml-0" }, [
              _c(
                "div",
                { staticClass: "nav-item displayDesktop" },
                [
                  _c(
                    "b-nav-item",
                    {
                      staticClass: "page-breadcrumb bold",
                      attrs: { disabled: "" },
                    },
                    [
                      _c("b-breadcrumb", {
                        staticClass: "breadcrumb",
                        attrs: { items: _vm.items, divider: "/" },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ]),
            _c("ul", { staticClass: "navbar-nav float-right" }, [
              _vm._m(3),
              _c("li", { staticClass: "nav-item displayDesktop" }, [
                _vm.nextUser
                  ? _c(
                      "a",
                      {
                        staticClass: "nav-link after-none notification-button",
                        attrs: {
                          id: "action-toggle-bell",
                          title: "Notifications",
                        },
                        on: { click: _vm.togglePush },
                      },
                      [
                        _c("v-icon", { attrs: { dark: "", medium: "" } }, [
                          _vm._v(
                            _vm._s(
                              _vm.isPushEnabled
                                ? "notifications"
                                : "notifications_off"
                            )
                          ),
                        ]),
                      ],
                      1
                    )
                  : _vm._e(),
              ]),
              _c("li", { staticClass: "nav-item displayDesktop" }, [
                _vm.nextUser && _vm.logout
                  ? _c(
                      "a",
                      {
                        staticClass: "nav-link after-none",
                        attrs: { id: "action-logout", title: "Se déconnecter" },
                        on: { click: _vm.logout },
                      },
                      [_c("i", { staticClass: "fas fa-lg fa-power-off" })]
                    )
                  : _vm._e(),
              ]),
            ]),
          ]
        ),
      ]
    ),
    _vm._m(4),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "progress progress-tp progress-loader d-none",
        staticStyle: { position: "relative", height: "4px" },
      },
      [_c("div", { staticClass: "progress-bar indeterminate tp" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "nav-toggler displayTP burger",
        attrs: { href: "javascript:void(0)" },
      },
      [_c("i", { staticClass: "fas fa-bars fa-lg" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "sidebartoggler nav-toggler displayDesktop burger",
        attrs: { href: "javascript:void(0)" },
      },
      [_c("i", { staticClass: "fas fa-bars fa-lg" })]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("li", { staticClass: "nav-item fullscreen displayDesktop" }, [
      _c(
        "a",
        {
          staticClass: "nav-link",
          attrs: { id: "action_toggle_fullscreen", title: "Plein écran" },
        },
        [_c("i", { staticClass: "fas fa-expand fa-lg" })]
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "div",
      {
        staticClass: "progress progress-desktop progress-loader d-none",
        staticStyle: { position: "relative", height: "4px" },
      },
      [_c("div", { staticClass: "progress-bar indeterminate desktop" })]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }