// import Vue from "vue";
// import axios from "axios";

const showNotifications = {
  namespaced: true,
  state: {
    showNotification: {
      ShowError: true,
      ShowSucces: false,
      ShowWarning: false,
      ShowInfo: false,
    },
  },
  mutations: {
    showError(state, newState) {
      state.showNotification.showError = newState;
    },
    showSucces(state, newState) {
      state.showNotification.showSucces = newState;
    },
    showWarning(state, newState) {
      state.showNotification.showWarning = newState;
    },
    showInfo(state, newState) {
      state.showNotification.showInfo = newState;
    },
  },
  actions: {
    updateNotification({ commit, state }, newNotification) {
      return new Promise((resolve, reject) => {
        if (newNotification) {
          commit("showNotification.showError", newNotification.showNotification.showError);
          resolve(state.showError);

          commit("showNotification.showSucces", newNotification.showNotification.showSucces);
          resolve(state.showSucces);

          commit("showNotification.showWarning", newNotification.showNotification.showWarning);
          resolve(state.showWarning);

          commit("showNotification.showInfo", newNotification.showNotification.showInfo);
          resolve(state.showNotification.showInfo);
        } else {
          reject();
        }

        // todo => implémenter l'API User.
        // axios
        //   .get("https://user-api.com/UpdateCurrentCustomer?newCompany=newCompany", {
        //     headers: [{ Authorization: "Bearer " + Vue.prototype.$keycloak.token }]
        //   })
        //   .then(response => {
        //     if (response.status == 200) {
        //       Vue.prototype.$keycloak
        //         .updateToken(99999)
        //         .then(resp => {
        //           if (resp.status == 200) {
        //             commit("currentCustomer", Vue.prototype.$keycloak.tokenParsed.current_customer);
        //             resolve(state.currentCustomer);
        //           } else {
        //             reject(resp.statusText);
        //           }
        //         })
        //         .catch(err => {
        //           Vue.$log.error("Error updateToken: " + err);
        //           reject(err);
        //         });
        //     } else {
        //       reject(response.statusText);
        //     }
        //   })
        //   .catch(error => {
        //     Vue.$log.error(error);
        //     reject("Error updateCurrentCustomer from User API: " + error);
        //   });
      });
    },
  },
};

export default showNotifications;
