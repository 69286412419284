import { extend } from "vee-validate";
import moment from "moment";

export function addCustomRules() {
  // Check if the date is in the future. (After today)
  extend("date_after_today", {
    validate(value) {
      return moment(value).isAfter();
    },
    message: "La date doit être supérieure à aujourd'hui.",
  });

  extend("date_after_equal_today", {
    validate(value) {
      return moment().isSameOrBefore(value, "day");
    },
    message: "La date doit être supérieure ou égale à aujourd'hui.",
  });
}
