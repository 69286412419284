<template>
  <v-app id="app">
    <div class="v-application--wrap">
      <div id="main-wrapper">
        <app-header />
        <app-menu />
        <main class="page-wrapper">
          <modalInfo />
          <v-main>
            <v-container fluid>
              <router-view />
            </v-container>
          </v-main>
          <app-footer />
        </main>
      </div>
    </div>
  </v-app>
</template>

<script>
import { AppHeader, AppFooter, AppMenu } from "@/app/shared/components";
import modalInfo from "@/app/shared/components/modal-info/modal-info.vue";

export default {
  name: "App",

  components: {
    AppHeader,
    AppFooter,
    AppMenu,
    modalInfo,
  },

  computed: {
    isAuthenticated: function () {
      return this.$keycloak.authenticated;
    },

    token: function () {
      return this.$keycloak.tokenParsed;
    },
  },

  watch: {
    // eslint-disable-next-line no-unused-vars
    isAuthenticated: function (newValue, oldValue) {
      if (!newValue) {
        this.$router.go();
      }
    },

    // eslint-disable-next-line no-unused-vars
    token: function (newValue, oldValue) {
      if (!newValue || newValue === {}) {
        this.$router.go();
      }
    },
  },

  mounted() {
    const loaderScript = document.createElement("script");
    loaderScript.setAttribute("src", "/js/loader.js");
    document.head.appendChild(loaderScript);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss"></style>
