var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("aside", { staticClass: "left-sidebar" }, [
    _c("div", { staticClass: "scroll-sidebar" }, [
      _c("nav", { staticClass: "sidebar-nav" }, [
        _c("img", {
          staticClass: "logo",
          attrs: { src: "/img/logo_Next.png", alt: "Next" },
        }),
        _c("ul", { attrs: { id: "sidebarnav" } }, [
          !_vm.nextUser
            ? _c(
                "li",
                { staticClass: "sidebar-item" },
                [
                  _c(
                    "router-link",
                    { staticClass: "sidebar-link", attrs: { to: "/Home" } },
                    [
                      _c("i", { staticClass: "fa fa-power-off fa-2x" }),
                      _c("span", { staticClass: "hide-menu" }, [
                        _vm._v("Connexion"),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.nextUser
            ? _c(
                "li",
                { staticClass: "sidebar-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "sidebar-link",
                      attrs: { to: "/Home", "active-class": "active" },
                    },
                    [
                      _c("i", { staticClass: "fas fa-home fa-2x" }),
                      _c("span", { staticClass: "hide-menu" }, [
                        _vm._v("Accueil"),
                      ]),
                    ]
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.nextUser && _vm.rights["Order.Web"]
            ? _c("li", { staticClass: "sidebar-item" }, [
                _vm._m(0),
                _c(
                  "ul",
                  {
                    staticClass: "collapse first-level",
                    attrs: { "aria-expanded": "false" },
                  },
                  [
                    _c(
                      "li",
                      { staticClass: "sidebar-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "sidebar-link",
                            attrs: {
                              to: "/Order/ProductSupplier",
                              "active-class": "active",
                            },
                          },
                          [
                            _c("i", { staticClass: "fas fa-cart-arrow-down" }),
                            _c("span", { staticClass: "hide-menu" }, [
                              _vm._v("Commander"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      { staticClass: "sidebar-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "sidebar-link",
                            attrs: {
                              to: "/Order/OrderSupplier",
                              "active-class": "active",
                            },
                          },
                          [
                            _c("i", { staticClass: "fas fa-shopping-cart" }),
                            _c("span", { staticClass: "hide-menu" }, [
                              _vm._v("Mes commandes"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.nextUser && (_vm.rights["Stock.Web"] || _vm.rights["Inventory"])
            ? _c("li", { staticClass: "sidebar-item" }, [
                _vm._m(1),
                _c(
                  "ul",
                  {
                    staticClass: "collapse first-level",
                    attrs: { "aria-expanded": "false" },
                  },
                  [
                    _vm.nextUser && _vm.rights["Stock.Web"]
                      ? _c(
                          "li",
                          { staticClass: "sidebar-item" },
                          [
                            _vm.isMobile
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "sidebar-link",
                                    attrs: {
                                      to: "/Stock/ReceptionMobile",
                                      "active-class": "active",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-sign-in-alt",
                                    }),
                                    _c("span", { staticClass: "hide-menu" }, [
                                      _vm._v("Réception de marchandises"),
                                    ]),
                                  ]
                                )
                              : _c(
                                  "router-link",
                                  {
                                    staticClass: "sidebar-link",
                                    attrs: {
                                      to: "/Stock/Reception",
                                      "active-class": "active",
                                    },
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-sign-in-alt",
                                    }),
                                    _c("span", { staticClass: "hide-menu" }, [
                                      _vm._v("Réception de marchandises"),
                                    ]),
                                    _c(
                                      "span",
                                      {
                                        staticClass:
                                          "badge badge-pill badge-warning up rounded-circle badge-menu badge-Reception",
                                        staticStyle: {
                                          color: "#212529",
                                          display: "none",
                                        },
                                      },
                                      [_vm._v("1")]
                                    ),
                                  ]
                                ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.nextUser && _vm.rights["Stock.Web"]
                      ? _c(
                          "li",
                          { staticClass: "sidebar-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "sidebar-link",
                                attrs: {
                                  to: "/Stock/Stock",
                                  "active-class": "active",
                                },
                              },
                              [
                                _c("i", { staticClass: "fas fa-layer-group" }),
                                _c("span", { staticClass: "hide-menu" }, [
                                  _vm._v("Gestion du stock"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.nextUser && _vm.rights["Stock.Web"]
                      ? _c(
                          "li",
                          { staticClass: "sidebar-item" },
                          [
                            _vm.isMobile && _vm.currentTenant != "leclerc"
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "sidebar-link",
                                    attrs: {
                                      to: "/Stock/PreparationMobile",
                                      "active-class": "active",
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "fas fa-dolly" }),
                                    _c("span", { staticClass: "hide-menu" }, [
                                      _vm._v("Préparation de commande"),
                                    ]),
                                  ]
                                )
                              : _c(
                                  "router-link",
                                  {
                                    staticClass: "sidebar-link",
                                    attrs: {
                                      to: "/Stock/Preparation",
                                      "active-class": "active",
                                    },
                                  },
                                  [
                                    _c("i", { staticClass: "fas fa-dolly" }),
                                    _c("span", { staticClass: "hide-menu" }, [
                                      _vm._v("Préparation de commande"),
                                    ]),
                                  ]
                                ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.nextUser && _vm.rights["Stock.Web"]
                      ? _c(
                          "li",
                          { staticClass: "sidebar-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "sidebar-link",
                                attrs: {
                                  to: "/Stock/AddressTransfert",
                                  "active-class": "active",
                                },
                              },
                              [
                                _c("i", { staticClass: "fas fa-people-carry" }),
                                _c("span", { staticClass: "hide-menu" }, [
                                  _vm._v("Transfert d'adresse"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.nextUser && _vm.rights["Stock.Web"]
                      ? _c(
                          "li",
                          { staticClass: "sidebar-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "sidebar-link",
                                attrs: {
                                  to: "/Stock/Regularisation",
                                  "active-class": "active",
                                },
                              },
                              [
                                _c("i", { staticClass: "fas fa-box-open" }),
                                _c("span", { staticClass: "hide-menu" }, [
                                  _vm._v("Correction de stock"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.nextUser && _vm.rights["Inventory"]
                      ? _c(
                          "li",
                          { staticClass: "sidebar-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "sidebar-link",
                                attrs: {
                                  to: "/Stock/Inventory",
                                  "active-class": "active",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-clipboard-list",
                                }),
                                _c("span", { staticClass: "hide-menu" }, [
                                  _vm._v("Inventaire"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.nextUser && _vm.rights["Stock.Web"]
                      ? _c(
                          "li",
                          { staticClass: "sidebar-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "sidebar-link",
                                attrs: {
                                  to: "/Stock/Location",
                                  "active-class": "active",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-map-marker-alt",
                                }),
                                _c("span", { staticClass: "hide-menu" }, [
                                  _vm._v("Emplacement"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.nextUser && _vm.rights["Stock.Web"]
                      ? _c(
                          "li",
                          { staticClass: "sidebar-item" },
                          [
                            !_vm.isProduction
                              ? _c(
                                  "router-link",
                                  {
                                    staticClass: "sidebar-link",
                                    attrs: {
                                      to: "/Stock/Location2",
                                      "active-class": "active",
                                    },
                                  },
                                  [
                                    _c(
                                      "v-badge",
                                      {
                                        attrs: {
                                          value: true,
                                          color: "red",
                                          dot: "",
                                        },
                                      },
                                      [
                                        _c(
                                          "v-tooltip",
                                          {
                                            attrs: { right: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                {
                                                  key: "activator",
                                                  fn: function ({ on, attrs }) {
                                                    return [
                                                      _c("i", {
                                                        staticClass:
                                                          "fas fa-map",
                                                      }),
                                                      _c(
                                                        "span",
                                                        _vm._g(
                                                          _vm._b(
                                                            {
                                                              staticClass:
                                                                "hide-menu",
                                                            },
                                                            "span",
                                                            attrs,
                                                            false
                                                          ),
                                                          on
                                                        ),
                                                        [_vm._v("Supervision")]
                                                      ),
                                                    ]
                                                  },
                                                },
                                              ],
                                              null,
                                              false,
                                              2386832852
                                            ),
                                          },
                                          [_c("span", [_vm._v("BETA")])]
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.nextUser && _vm.rights["Order.Web"]
            ? _c("li", { staticClass: "sidebar-item" }, [
                _vm._m(2),
                _c(
                  "ul",
                  {
                    staticClass: "collapse first-level",
                    attrs: { "aria-expanded": "false" },
                  },
                  [
                    _c(
                      "li",
                      { staticClass: "sidebar-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "sidebar-link",
                            attrs: {
                              to: "/Order/Product",
                              "active-class": "active",
                            },
                          },
                          [
                            _c("i", { staticClass: "fas fa-cart-arrow-down" }),
                            _c("span", { staticClass: "hide-menu" }, [
                              _vm._v("Commander"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      { staticClass: "sidebar-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "sidebar-link",
                            attrs: {
                              to: "/Order/Order",
                              "active-class": "active",
                            },
                          },
                          [
                            _c("i", { staticClass: "fas fa-shopping-cart" }),
                            _c("span", { staticClass: "hide-menu" }, [
                              _vm._v("Liste des commandes"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.nextUser && (_vm.rights["Delivery.Web"] || _vm.rights["Shipment"])
            ? _c("li", { staticClass: "sidebar-item" }, [
                _vm._m(3),
                _c(
                  "ul",
                  {
                    staticClass: "collapse first-level",
                    attrs: { "aria-expanded": "false" },
                  },
                  [
                    _vm.nextUser && _vm.rights["Shipment"]
                      ? _c(
                          "li",
                          { staticClass: "sidebar-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "sidebar-link",
                                attrs: {
                                  to: "/Delivery/Shipment",
                                  "active-class": "active",
                                },
                              },
                              [
                                _c("i", { staticClass: "fas fa-truck" }),
                                _c("span", { staticClass: "hide-menu" }, [
                                  _vm._v("Expédition"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.nextUser && _vm.rights["Delivery.Web"]
                      ? _c(
                          "li",
                          { staticClass: "sidebar-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "sidebar-link",
                                attrs: {
                                  to: "/Delivery",
                                  "active-class": "active",
                                },
                              },
                              [
                                _c("i", {
                                  staticClass: "fas fa-truck-loading",
                                }),
                                _c("span", { staticClass: "hide-menu" }, [
                                  _vm._v("Livraison"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.nextUser && _vm.rights["Document.Web"]
            ? _c("li", { staticClass: "sidebar-item" }, [
                _vm._m(4),
                _c(
                  "ul",
                  {
                    staticClass: "collapse first-level",
                    attrs: { "aria-expanded": "false" },
                  },
                  [
                    _c(
                      "li",
                      { staticClass: "sidebar-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "sidebar-link",
                            attrs: {
                              to: "/Document/Document",
                              "active-class": "active",
                            },
                          },
                          [
                            _c("i", { staticClass: "far fa-file-alt" }),
                            _c("span", { staticClass: "hide-menu" }, [
                              _vm._v("Document"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _vm.nextUser.isSuperAdmin
                      ? _c(
                          "li",
                          { staticClass: "sidebar-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "sidebar-link",
                                attrs: {
                                  to: "/Document/Printing",
                                  "active-class": "active",
                                },
                              },
                              [
                                _c("i", { staticClass: "fas fa-print" }),
                                _c("span", { staticClass: "hide-menu" }, [
                                  _vm._v("Impression"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "li",
                      { staticClass: "sidebar-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "sidebar-link",
                            attrs: {
                              to: "/Document/Printer",
                              "active-class": "active",
                            },
                          },
                          [
                            _c("i", { staticClass: "fas fa-print" }),
                            _c("span", { staticClass: "hide-menu" }, [
                              _vm._v("Imprimante"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
          _vm.nextUser &&
          (_vm.rights["Repository.Web"] || _vm.rights["User.Web"])
            ? _c("li", { staticClass: "sidebar-item" }, [
                _vm._m(5),
                _c(
                  "ul",
                  {
                    staticClass: "collapse first-level",
                    attrs: { "aria-expanded": "false" },
                  },
                  [
                    _vm.rights["Repository.Web"]
                      ? _c(
                          "li",
                          { staticClass: "sidebar-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "sidebar-link",
                                attrs: {
                                  to: "/Repository/Product",
                                  "active-class": "active",
                                },
                              },
                              [
                                _c("i", { staticClass: "fa fa-box-open" }),
                                _c("span", { staticClass: "hide-menu" }, [
                                  _vm._v("Référentiel produit"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.rights["Repository.Web"]
                      ? _c(
                          "li",
                          { staticClass: "sidebar-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "sidebar-link",
                                attrs: {
                                  to: "/Repository/ProductFamily",
                                  "active-class": "active",
                                },
                              },
                              [
                                _c("i", { staticClass: "fa fa-boxes" }),
                                _c("span", { staticClass: "hide-menu" }, [
                                  _vm._v("Famille de produit"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.rights["Repository.Web"]
                      ? _c(
                          "li",
                          { staticClass: "sidebar-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "sidebar-link",
                                attrs: {
                                  to: "/Repository/Attributes",
                                  "active-class": "active",
                                },
                              },
                              [
                                _c("i", { staticClass: "fas fa-tag" }),
                                _c("span", { staticClass: "hide-menu" }, [
                                  _vm._v("Attributs"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.rights["Repository.Web"]
                      ? _c(
                          "li",
                          { staticClass: "sidebar-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "sidebar-link",
                                attrs: {
                                  to: "/Repository/GroupAttributes",
                                  "active-class": "active",
                                },
                              },
                              [
                                _c("i", { staticClass: "fas fa-tags" }),
                                _c("span", { staticClass: "hide-menu" }, [
                                  _vm._v("Groupes d'attributs"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.rights["Repository.Web"]
                      ? _c(
                          "li",
                          { staticClass: "sidebar-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "sidebar-link",
                                attrs: {
                                  to: "/Repository/Company",
                                  "active-class": "active",
                                },
                              },
                              [
                                _c("i", { staticClass: "fas fa-city" }),
                                _c("span", { staticClass: "hide-menu" }, [
                                  _vm._v("Référentiel tiers"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm.rights["User.Web"]
                      ? _c(
                          "li",
                          { staticClass: "sidebar-item" },
                          [
                            _c(
                              "router-link",
                              {
                                staticClass: "sidebar-link",
                                attrs: {
                                  to: "/Admin/User",
                                  "active-class": "active",
                                },
                              },
                              [
                                _c("i", { staticClass: "fas fa-user-friends" }),
                                _c("span", { staticClass: "hide-menu" }, [
                                  _vm._v("Utilisateur"),
                                ]),
                              ]
                            ),
                          ],
                          1
                        )
                      : _vm._e(),
                  ]
                ),
              ])
            : _vm._e(),
          _c("li", { staticClass: "sidebar-item" }, [
            _vm._m(6),
            _c(
              "ul",
              {
                staticClass: "collapse first-level",
                attrs: { "aria-expanded": "false" },
              },
              [
                _c(
                  "li",
                  { staticClass: "sidebar-item" },
                  [
                    _c(
                      "router-link",
                      {
                        staticClass: "sidebar-link",
                        attrs: { to: "/Contact", "active-class": "active" },
                      },
                      [
                        _c("i", { staticClass: "fas fa-headset" }),
                        _c("span", { staticClass: "hide-menu" }, [
                          _vm._v("Nous contacter"),
                        ]),
                      ]
                    ),
                  ],
                  1
                ),
              ]
            ),
          ]),
          _vm.nextUser
            ? _c(
                "li",
                { staticClass: "sidebar-item" },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "sidebar-link",
                      attrs: { to: "/Profile", "active-class": "active" },
                    },
                    [
                      _c("avatar", {
                        staticClass: "avatar",
                        attrs: {
                          username: _vm.fullname,
                          color: "white",
                          size: 40,
                        },
                      }),
                      _c(
                        "span",
                        {
                          staticClass: "hide-menu",
                          staticStyle: { "margin-left": "10px" },
                        },
                        [_vm._v("Mon profil")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.nextUser && (!_vm.isProduction || _vm.nextUser.isSuperAdmin)
            ? _c("li", { staticClass: "sidebar-item" }, [
                _c(
                  "a",
                  {
                    staticClass: "sidebar-link has-arrow",
                    attrs: {
                      href: "javascript:void(0)",
                      "aria-expanded": "false",
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fas fa-flag",
                      style: `font-size: 1.5em !important; color: ${_vm.getEnvironmentColor()};`,
                    }),
                    _c("span", { staticClass: "hide-menu text-uppercase" }, [
                      _vm._v(_vm._s(_vm.environment)),
                    ]),
                  ]
                ),
                _c(
                  "ul",
                  {
                    staticClass: "collapse first-level",
                    attrs: { "aria-expanded": "false" },
                  },
                  [
                    _c(
                      "li",
                      { staticClass: "sidebar-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "sidebar-link",
                            attrs: {
                              to: "/Admin/Status",
                              "active-class": "active",
                            },
                          },
                          [
                            _c("i", { staticClass: "far fa-check-circle" }),
                            _c("span", { staticClass: "hide-menu" }, [
                              _vm._v("Status"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                    _c(
                      "li",
                      { staticClass: "sidebar-item" },
                      [
                        _c(
                          "router-link",
                          {
                            staticClass: "sidebar-link",
                            attrs: {
                              to: "/Admin/BugStatus",
                              "active-class": "active",
                            },
                          },
                          [
                            _c("i", { staticClass: "fas fa-bug" }),
                            _c("span", { staticClass: "hide-menu" }, [
                              _vm._v("BugStatus"),
                            ]),
                          ]
                        ),
                      ],
                      1
                    ),
                  ]
                ),
              ])
            : _vm._e(),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "sidebar-link has-arrow",
        attrs: { href: "javascript:void(0)", "aria-expanded": "false" },
      },
      [
        _c("i", { staticClass: "fas fa-industry fa-2x" }),
        _c("span", { staticClass: "hide-menu" }, [_vm._v("Approvisionnement")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "sidebar-link has-arrow",
        attrs: { href: "javascript:void(0)", "aria-expanded": "false" },
      },
      [
        _c("i", { staticClass: "fas fa-warehouse fa-2x" }),
        _c("span", { staticClass: "hide-menu" }, [_vm._v("Entreposage")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "sidebar-link has-arrow",
        attrs: { href: "javascript:void(0)", "aria-expanded": "false" },
      },
      [
        _c("i", { staticClass: "fas fa-shopping-basket fa-2x" }),
        _c("span", { staticClass: "hide-menu" }, [_vm._v("Commande")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "sidebar-link has-arrow",
        attrs: { href: "javascript:void(0)", "aria-expanded": "false" },
      },
      [
        _c("i", { staticClass: "fas fa-truck fa-2x" }),
        _c("span", { staticClass: "hide-menu" }, [_vm._v("Transport")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "sidebar-link has-arrow",
        attrs: { href: "javascript:void(0)", "aria-expanded": "false" },
      },
      [
        _c("i", { staticClass: "far fa-file-alt fa-2x" }),
        _c("span", { staticClass: "hide-menu" }, [_vm._v("Document")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "sidebar-link has-arrow",
        attrs: { href: "javascript:void(0)", "aria-expanded": "false" },
      },
      [
        _c("i", { staticClass: "fas fa-cog fa-2x" }),
        _c("span", { staticClass: "hide-menu" }, [_vm._v("Paramétrage")]),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      "a",
      {
        staticClass: "sidebar-link has-arrow",
        attrs: { href: "javascript:void(0)", "aria-expanded": "false" },
      },
      [
        _c("i", { staticClass: "fas fa-question-circle fa-2x" }),
        _c("span", { staticClass: "hide-menu" }, [_vm._v("Aide")]),
      ]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }