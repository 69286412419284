import store from "@/app/app-state";

const orderRoutes = [
  {
    path: "/Order",
    redirect: "/Order/Order",
  },
  {
    name: "Order.Web",
    path: "/Order/Product",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Commande",
        },
        {
          text: "Commander",
          to: "/order/Product",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "orderComponents" */ "./product/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Order.Web",
    path: "/Order/Order",
    meta: {
      allowAnonymous: false,
      roles: ["user"],
      breadcrumb: [
        {
          text: "Commande",
        },
        {
          text: "Liste des commandes",
          to: "/Order/Order",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "orderComponents" */ "./order/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined" && typeof to.meta.roles != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Order.Web",
    path: "/Order/ProductSupplier",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Approvisionnement",
        },
        {
          text: "Commander",
          to: "/order/ProductSupplier",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "orderComponents" */ "./productSupplier/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Order.Web",
    path: "/Order/OrderSupplier",
    meta: {
      allowAnonymous: false,
      roles: ["user"],
      breadcrumb: [
        {
          text: "Approvisionnement",
        },
        {
          text: "Mes commandes",
          to: "/Order/OrderSupplier",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "orderComponents" */ "./orderSupplier/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined" && typeof to.meta.roles != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
];

export default orderRoutes;
