import Vue from "vue";
import VueRouter from "vue-router";
import { homeRoutes } from "@/app/home";
import { deliveryRoutes } from "@/app/delivery";
import { documentRoutes } from "@/app/document";
import { orderRoutes } from "@/app/order";
import { repositoryRoutes } from "@/app/repository";
import { stockRoutes } from "@/app/stock";
import { adminRoutes } from "@/app/admin";
import { keycloakClientId } from "@/environment/environment";
import LocalStorage from "@/app/shared/js/localstorage.js";

Vue.use(VueRouter);

const appRoutes = [
  {
    path: "/",
    redirect: "/Home",
    meta: {
      allowAnonymous: false,
    },
  },
  {
    path: "*",
    name: "page-not-found",
    component: () => import(/* webpackChunkName: "AppComponents" */ "./shared/components/page-not-found/page-not-found.vue"),
    // component: PageNotFound,
    meta: {
      allowAnonymous: false,
    },
  },
  {
    path: "/Unauthorized",
    name: "page-unauthorized",
    component: () => import(/* webpackChunkName: "AppComponents" */ "./shared/components/page-unauthorized/page-unauthorized.vue"),
    // component: PageUnauthorized,
    meta: {
      allowAnonymous: false,
    },
  },
  {
    path: "/NotAccess",
    name: "page-not-access",
    component: () => import(/* webpackChunkName: "AppComponents" */ "./shared/components/page-not-access/page-not-access.vue"),
    // component: PageNotAccess,
    meta: {
      allowAnonymous: true,
    },
  },
];

const routes = [
  ...homeRoutes,
  ...deliveryRoutes,
  ...documentRoutes,
  ...orderRoutes,
  ...repositoryRoutes,
  ...stockRoutes,
  ...adminRoutes,
  ...appRoutes,
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

const redirectToLoginUrl = function (to) {
  const redirectUri = window.location.origin + to.fullPath;
  let loginUrl = router.app.$keycloak.createLoginUrl({
    postLogoutRedirectUri: redirectUri,
    idTokenHint: Vue.prototype.$keycloak.idToken,
  });
  window.location.replace(loginUrl);
};

router.beforeEach((to, _from, next) => {
  if (to.matched.some((record) => record.meta.allowAnonymous)) {
    next();
  } else {
    if (router.app.$keycloak.authenticated) {
      if (
        router.app.$keycloak.hasResourceRole("user", keycloakClientId) &&
        router.app.$store.state.userContext.nextUser &&
        router.app.$store.state.userContext.customers &&
        router.app.$store.state.userContext.customers.includes(router.app.$store.state.userContext.currentCustomer)
      ) {
        next();
      } else {
        next("/NotAccess", { replace: true, name: "page-not-access" });
      }
    } else {
      redirectToLoginUrl(to);
    }
  }
  LocalStorage.displayInfoLocalStorage();
});

router.onError((error) => {
  console.error(error);
  Vue.prototype.$log.error("Failure Reason: ", error.message, error);
  if (/ChunkLoadError:.*failed./i.test(error.message)) {
    Vue.prototype.$log.error("Reloading Window 1");
    window.location.reload();
  } else if (/Loading.*chunk.*failed./i.test(error.message)) {
    Vue.prototype.$log.error("Reloading Window 2");
    window.location.reload();
  }
});

export default router;
