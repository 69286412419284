import store from "@/app/app-state";

const adminRoutes = [
  {
    path: "/Admin",
    redirect: "/Admin/User",
  },
  {
    name: "User.Web",
    path: "/Admin/User",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Paramétrage",
        },
        {
          text: "Utilisateur",
          to: "/Admin/User",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "adminComponents" */ "./user/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "User.Web",
    path: "/Admin/Status",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Admin",
        },
        {
          text: "Status",
          to: "/Admin/Status",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "adminComponents" */ "./status/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "User.Web",
    path: "/Admin/BugStatus",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Admin",
        },
        {
          text: "BugStatus",
          to: "/Admin/BugStatus",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "adminComponents" */ "./bugstatus/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "User.Web",
    path: "/Admin/cab",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Admin",
        },
        {
          text: "CAB",
          to: "/Admin/cab",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "adminComponents" */ "./cab/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
];

export default adminRoutes;
