<template>
  <div
    class="modal fade"
    id="modalInfo"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    data-backdrop="false"
  >
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalCenterTitle">Modal title</h5>
        </div>
        <div class="modal-body">Body</div>
        <div class="modal-footer">
          <button type="button" id="btn-non" class="btn btn-light" @click="btnNo()">Non</button>
          <button type="button" id="btn-oui" class="btn btn-outline-success" data-dismiss="modal" @click="btnYes()">Oui</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script type="text/javascript">
import LocalStorage from "@/app/shared/js/localstorage.js";
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
export default {
  name: "modal-info",

  methods: {
    btnNo() {
      let modalInfo = document.getElementById("modalInfo");
      let firstKey = modalInfo.getAttribute("firstkey");
      let jsonValue = JSON.parse(localStorage.getItem("Next"));
      this.$router.push(jsonValue[firstKey].url);
      LocalStorage.removeItemLocalStorage("Next", firstKey);
      modalInfo.style.display = "none";
    },
    btnYes() {
      let firstkey = document.getElementById("modalInfo").getAttribute("firstkey");
      LocalStorage.updateKnowedLocalStorage("Next", firstkey);
    },
  },
};
</script>

<style lang="scss" scoped></style>
