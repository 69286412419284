/**
 * Infrastructure Configuration class
 * @description This will be responsible for retrieving API urls
 */

import RestClient from "./helpers/RestClient";

class InfrastructureConfiguration {
  constructor(configurationAPI) {
    this.loading = false;
    this.client = new RestClient(configurationAPI);
    this.restClient = new RestClient(configurationAPI).Instance;
  }

  async getConfiguration(key) {
    this.loading = true;
    let response = await this.restClient.get("Configurations/" + key);
    let data = response.data;
    this.loading = false;

    return data;
  }
}

export default InfrastructureConfiguration;
