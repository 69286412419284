<template>
  <header class="topbar">
    <div class="progress progress-tp progress-loader d-none" style="position: relative; height: 4px">
      <div class="progress-bar indeterminate tp"></div>
    </div>
    <nav class="navbar top-navbar navbar-expand-md navbar-dark bg-dark">
      <!-- TP Navbar -->
      <div class="navbar-header">
        <!-- This is for the sidebar toggle which is visible on mobile only -->
        <a class="nav-toggler displayTP burger" href="javascript:void(0)">
          <i class="fas fa-bars fa-lg"></i>
        </a>
        <b-nav-item disabled class="displayTP page-breadcrumb bold mr-auto">
          <b-breadcrumb class="breadcrumb" :items="items" divider="/"></b-breadcrumb>
        </b-nav-item>
        <!-- toggle and nav items -->
        <!-- Right side toggle and nav items -->
        <v-btn dark class="nav-link after-none notification-button ml-auto" v-if="nextUser" @click="togglePush" icon>
          <v-icon dark medium>{{ isPushEnabled ? "notifications" : "notifications_off" }}</v-icon>
        </v-btn>

        <v-btn dark class="nav-link after-none" v-if="nextUser && logout" @click="logout" icon>
          <i class="fas fa-power-off"></i>
        </v-btn>
      </div>

      <!-- Desktop Navbar -->
      <div class="navbar-collapse collapse" id="navbarSupportedContent">
        <a class="sidebartoggler nav-toggler displayDesktop burger" href="javascript:void(0)">
          <i class="fas fa-bars fa-lg"></i>
        </a>
        <!-- toggle and nav items -->
        <div class="navbar-nav float-left mr-auto ml-0">
          <div class="nav-item displayDesktop">
            <b-nav-item disabled class="page-breadcrumb bold">
              <b-breadcrumb class="breadcrumb" :items="items" divider="/"></b-breadcrumb>
            </b-nav-item>
          </div>
        </div>
        <!-- Right side toggle and nav items -->
        <ul class="navbar-nav float-right">
          <li class="nav-item fullscreen displayDesktop">
            <a id="action_toggle_fullscreen" class="nav-link" title="Plein écran">
              <i class="fas fa-expand fa-lg"></i>
            </a>
          </li>

          <li class="nav-item displayDesktop">
            <a
              id="action-toggle-bell"
              class="nav-link after-none notification-button"
              v-if="nextUser"
              @click="togglePush"
              title="Notifications"
            >
              <v-icon dark medium>{{ isPushEnabled ? "notifications" : "notifications_off" }}</v-icon>
            </a>
          </li>

          <li class="nav-item displayDesktop">
            <a id="action-logout" class="nav-link after-none" v-if="nextUser && logout" @click="logout" title="Se déconnecter">
              <i class="fas fa-lg fa-power-off"></i>
            </a>
          </li>
        </ul>
      </div>
    </nav>
    <div class="progress progress-desktop progress-loader d-none" style="position: relative; height: 4px">
      <div class="progress-bar indeterminate desktop"></div>
    </div>
  </header>
</template>

<style lang="scss" scoped>
@import "app-header.scss";
</style>

<script>
import { mapState } from "vuex";
import SubscriptionNotificationPush from "@/app/shared/services/subscriptionNotificationPush";
export default {
  name: "app-header",

  data() {
    return {
      year: new Date().getFullYear(),
      isPushEnabled: false,
      logout: this.$keycloak.logoutFn,
    };
  },

  computed: {
    ...mapState("userContext", ["rights", "nextUser"]),
    ...mapState("urlApi", ["urls"]),
    ...mapState("showNotifications", ["showNotification"]),

    items: function () {
      return this.$route.meta.breadcrumb;
    },
  },
  methods: {
    async registerServiceWorker() {
      /* Register the service worker. */
      if (!("serviceWorker" in navigator)) {
        console.log("Service workers aren't supported in this browser.");
        return;
      }
      await navigator.serviceWorker.register("/serviceworker.js").then(async () => await this.initialiseServiceWorker());
    },

    async initialiseServiceWorker() {
      if (!("showNotification" in ServiceWorkerRegistration.prototype)) {
        console.log("Notifications aren't supported.");
        return;
      }
      if (Notification.permission === "denied") {
        console.log("The user has blocked notifications.");
        return;
      }
      if (!("PushManager" in window)) {
        console.log("Push messaging isn't supported.");
        return;
      }

      await navigator.serviceWorker.ready.then((registration) => {
        registration.pushManager
          .getSubscription()
          .then(async (subscription) => {
            if (!subscription) {
              return;
            }
            await this.updateSubscription(subscription);
            this.isPushEnabled = true;
          })
          .catch((e) => {
            console.log("Error during getSubscription()", e);
          });
      });
    },

    async subscribe() {
      /* Subscribe for push notifications. */
      navigator.serviceWorker.ready.then((registration) => {
        const options = { userVisibleOnly: true };
        const vapidPublicKey = process.env.VUE_APP_VAPID_PUBLIC_KEY;

        if (vapidPublicKey) {
          options.applicationServerKey = this.urlBase64ToUint8Array(vapidPublicKey);
        }

        registration.pushManager
          .subscribe(options)
          .then(async (subscription) => {
            this.isPushEnabled = true;
            await this.updateSubscription(subscription);
          })
          .catch((e) => {
            if (Notification.permission === "denied") {
              console.log("Permission for Notifications was denied");
            } else {
              console.log("Unable to subscribe to push.", e);
            }
          });
      });
    },

    async unsubscribe() {
      /* Subscribe for push notifications. */
      navigator.serviceWorker.ready.then((registration) => {
        registration.pushManager
          .getSubscription()
          .then(async (subscription) => {
            subscription
              .unsubscribe()
              .then(async (successful) => {
                if (successful) {
                  this.isPushEnabled = false;
                  console.log("You've successfully unsubscribed");
                }
              })
              .catch(function (e) {
                console.log("Unsubscription failed", e);
              });
          })
          .catch((e) => {
            console.log("Unable to unsubscribe.", e);
          });
      });
    },

    togglePush() {
      /* Toggle push notifications subscription. */
      if (this.isPushEnabled) {
        this.unsubscribe();
      } else {
        this.subscribe();
      }
    },

    async updateSubscription(subscription) {
      /* Send a request to the server to update user's subscription. @param {PushSubscription} subscription */
      let jsonSubscription = JSON.parse(JSON.stringify(subscription));
      // Create body for the request
      let requestBody = {
        pushEndPoint: jsonSubscription.endpoint,
        pushP256DH: jsonSubscription.keys.p256dh,
        pushAuth: jsonSubscription.keys.auth,
      };

      this.SubscriptionNotificationPush = new SubscriptionNotificationPush(this.urls.User, this.showNotification);
      await this.SubscriptionNotificationPush.subscriptions(requestBody);
    },

    urlBase64ToUint8Array(base64String) {
      const padding = "=".repeat((4 - (base64String.length % 4)) % 4);
      // eslint-disable-next-line no-useless-escape
      const base64 = (base64String + padding).replace(/\-/g, "+").replace(/_/g, "/");
      const rawData = window.atob(base64);
      const outputArray = new Uint8Array(rawData.length);

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
      }

      return outputArray;
    },
  },

  async mounted() {
    if (this.nextUser) {
      await this.registerServiceWorker();
    }
  },
};
</script>
