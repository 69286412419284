import userContextService from "./shared/services/userContextService";
import urlApiService from "./shared/services/urlApiService";
import showNotificationsService from "./shared/services/showNotificationsService";
import featuresFlippingService from "./shared/services/featuresFlippingService";

const appServices = class {
  async start() {
    // Appel de setCurrentCustomer() en amont en raison d'une dépendance circulaire entre urlApiService et userContextService
    userContextService.setCurrentCustomer();
    await urlApiService.start();
    await userContextService.start();
    showNotificationsService.start();
    await featuresFlippingService.start();
  }
};

export default new appServices();
