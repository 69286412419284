import JQuery from "jquery";
let $ = JQuery;

class SuperMenu {
  toggleFullScreen() {
    if (
      !document.fullscreenElement &&
      // alternative standard method
      !document.mozFullScreenElement &&
      !document.webkitFullscreenElement &&
      !document.msFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.msRequestFullscreen) {
        document.documentElement.msRequestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(Element.ALLOW_KEYBOARD_INPUT);
      }
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.msExitFullscreen) {
        document.msExitFullscreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitExitFullscreen) {
        document.webkitExitFullscreen();
      }
    }
  }

  initializeSupermenu() {
    window.addEventListener("toggleFullScreenEvent", this.toggleFullScreen, false);

    // ==============================================================
    // sidebar-hover
    // ==============================================================
    $(".left-sidebar").hover(
      function () {
        $(".navbar-header").addClass("expand-logo");
      },
      function () {
        $(".navbar-header").removeClass("expand-logo");
      }
    );
    // this is for close icon when navigation open in mobile view
    $(".nav-toggler.displayTP").on("click", function () {
      $("#main-wrapper").toggleClass("show-sidebar");
      $(".nav-toggler.displayTP i").toggleClass("ti-menu");
    });
    $(".nav-lock").on("click", function () {
      $("body").toggleClass("lock-nav");
      $(".nav-lock i").toggleClass("mdi-toggle-switch-off");
      $("body, .page-wrapper").trigger("resize");
    });
    $(".search-box i").on("click", function () {
      $(".app-search input").focus();
    });

    // ==============================================================
    // This is for the floating labels
    // ==============================================================
    $(".floating-labels .form-control")
      .on("focus blur", function (e) {
        $(this)
          .parents(".form-group")
          .toggleClass("focused", e.type === "focus" || this.value.length > 0);
      })
      .trigger("blur");

    // ==============================================================
    // Resize all elements
    // ==============================================================
    $("body, .page-wrapper").trigger("resize");
    $(".page-wrapper").delay(20).show();
    // ==============================================================
    // To do list
    // ==============================================================
    $(".list-task li label").click(function () {
      $(this).toggleClass("task-done");
    });

    //****************************
    /* This is for the mini-sidebar if width is less then 1170*/
    //****************************
    var setsidebartype = function () {
      var width = window.innerWidth > 0 ? window.innerWidth : this.screen.width;
      if (width < 1170) {
        $("#main-wrapper").attr("data-sidebartype", "mini-sidebar");
      } else {
        $("#main-wrapper").attr("data-sidebartype", "full");
      }
    };
    $(window).ready(setsidebartype);
    $(window).on("resize", setsidebartype);
    //****************************
    /* This is for sidebartoggler*/
    //****************************
    $(".sidebartoggler").on("click", function () {
      $("#main-wrapper").toggleClass("mini-sidebar");
      if ($("#main-wrapper").hasClass("mini-sidebar")) {
        $(".sidebartoggler").prop("checked", !0);
        $("#main-wrapper").attr("data-sidebartype", "mini-sidebar");
      } else {
        $(".sidebartoggler").prop("checked", !1);
        $("#main-wrapper").attr("data-sidebartype", "full");
      }
    });

    $(".sidebar-link").on("click", function () {
      if (!$(this).hasClass("has-arrow")) {
        $("#main-wrapper").toggleClass("show-sidebar");
        $("#main-wrapper").toggleClass("mini-sidebar");
      }
    });

    $(".selector-toggle").on("click", function () {
      $("#infoSelector").toggleClass("open");
      $("#iconeSelector").toggleClass("fa-cog").toggleClass("fa-times").toggleClass("fa-spin");
    });
  }
}

export default new SuperMenu();
