const {
  VUE_APP_KEYCLOAK_URL,
  VUE_APP_KEYCLOAK_CLIENT_ID,
  VUE_APP_KEYCLOAK_REALM,
  VUE_APP_API_CONFIGURATION,
  VUE_APP_URL_TO_CUSTOMER,
  VUE_APP_ENVIRONMENT,
  VUE_APP_METABASE_SITE_URL,
  VUE_APP_METABASE_SECRET_KEY,
} = process.env;

// récupération des correspondances url => customer
let tmp = {};
// le format à respecter pour la variable d'environement est url1|Customer1,url2|customer2,url3|Customer3,...
VUE_APP_URL_TO_CUSTOMER.split(",").forEach((uc) => (tmp[uc.split("|")[0].toLowerCase()] = uc.split("|")[1]));
const urlToCustomer = tmp;

const environment = VUE_APP_ENVIRONMENT.toLowerCase();
const keycloakUrl = VUE_APP_KEYCLOAK_URL;
const keycloakClientId = VUE_APP_KEYCLOAK_CLIENT_ID;
const keycloakRealm = VUE_APP_KEYCLOAK_REALM;
const apiConfiguration = VUE_APP_API_CONFIGURATION;
const metabaseSiteUrl = VUE_APP_METABASE_SITE_URL;
const metabaseSecretKey = VUE_APP_METABASE_SECRET_KEY;

export { environment, keycloakUrl, keycloakClientId, keycloakRealm, apiConfiguration, urlToCustomer, metabaseSiteUrl, metabaseSecretKey };
