var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("v-app", { attrs: { id: "app" } }, [
    _c("div", { staticClass: "v-application--wrap" }, [
      _c(
        "div",
        { attrs: { id: "main-wrapper" } },
        [
          _c("app-header"),
          _c("app-menu"),
          _c(
            "main",
            { staticClass: "page-wrapper" },
            [
              _c("modalInfo"),
              _c(
                "v-main",
                [
                  _c(
                    "v-container",
                    { attrs: { fluid: "" } },
                    [_c("router-view")],
                    1
                  ),
                ],
                1
              ),
              _c("app-footer"),
            ],
            1
          ),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }