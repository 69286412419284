import JQuery from "jquery";
let $ = JQuery;

class LocalStorage {
  displayInfoLocalStorage() {
    //localStorage => récup des infos
    try {
      let jsonValue = JSON.parse(localStorage.getItem("Next"));
      if (jsonValue != null && jsonValue != "") {
        var firstKey = Object.keys(jsonValue)[0];
        if (firstKey == "Reception") {
          $(".badge-" + firstKey).show();
          $(".badge-" + firstKey).text("1");
        }

        if (!jsonValue[firstKey].knowed) {
          $(".modal-title").html('<i class="fas fa-exclamation-triangle text-warning mr-2"></i>' + jsonValue[firstKey].title);
          $(".modal-body").text(jsonValue[firstKey].message);
          let modal = document.getElementById("modalInfo");
          if (modal) {
            modal.setAttribute("firstkey", firstKey);
          }
          if (jsonValue[firstKey].url != window.location.pathname) {
            $("#modalInfo").modal("show");
            modal.style.display = "block";
          }
        }
      } else {
        $(".badge-Reception").hide();
        $(".badge-Reception").text("1");
      }
    } catch (error) {
      console.log(`Socle Display LocalStorage error ` + error);
    }
  }

  getItemLocalStorage(key) {
    try {
      var value = localStorage.getItem(key);
      var valueParsed = JSON.parse(value);
      return valueParsed;
    } catch (error) {
      console.log(`GetItem LocalStorage error ` + error);
    }
  }

  removeItemLocalStorage(key, module) {
    try {
      var value = {};
      var obj = this.getItemLocalStorage(key);
      if (obj != null) {
        value = obj;
      }
      delete value[module];
      let valueParsed = JSON.stringify(value);
      localStorage.setItem(key, valueParsed);

      if (Object.keys(value).length === 0) {
        localStorage.removeItem(key);
      }
    } catch (error) {
      console.log(`RemoveItem LocalStorage error ` + error);
    }
  }

  updateKnowedLocalStorage(key, module) {
    try {
      var value = {};
      var obj = this.getItemLocalStorage(key);
      if (obj != null) {
        value = obj;
      }
      value[module].knowed = true;

      let valueParsed = JSON.stringify(value);
      localStorage.setItem(key, valueParsed);
    } catch (error) {
      console.log(`UpdateKnowed LocalStorage error ` + error);
    }
  }
}

export default new LocalStorage();
