var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "modal fade",
      attrs: {
        id: "modalInfo",
        tabindex: "-1",
        role: "dialog",
        "aria-labelledby": "exampleModalCenterTitle",
        "aria-hidden": "true",
        "data-backdrop": "false",
      },
    },
    [
      _c(
        "div",
        {
          staticClass: "modal-dialog modal-dialog-centered",
          attrs: { role: "document" },
        },
        [
          _c("div", { staticClass: "modal-content" }, [
            _vm._m(0),
            _c("div", { staticClass: "modal-body" }, [_vm._v("Body")]),
            _c("div", { staticClass: "modal-footer" }, [
              _c(
                "button",
                {
                  staticClass: "btn btn-light",
                  attrs: { type: "button", id: "btn-non" },
                  on: {
                    click: function ($event) {
                      return _vm.btnNo()
                    },
                  },
                },
                [_vm._v("Non")]
              ),
              _c(
                "button",
                {
                  staticClass: "btn btn-outline-success",
                  attrs: {
                    type: "button",
                    id: "btn-oui",
                    "data-dismiss": "modal",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.btnYes()
                    },
                  },
                },
                [_vm._v("Oui")]
              ),
            ]),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "modal-header" }, [
      _c(
        "h5",
        {
          staticClass: "modal-title",
          attrs: { id: "exampleModalCenterTitle" },
        },
        [_vm._v("Modal title")]
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }