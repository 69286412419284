import Vue from "vue";
import store from "@/app/app-state";
import FeatureFlippingStore from "./stores/FeatureFlippingStore";

const featuresFlippingService = class {
  async start() {
    await this.setPrintAuthorise();
  }

  async setPrintAuthorise() {
    try {
      let documentAPI = store.state.urlApi.urls.Document;
      this.FeatureFlippingStore = new FeatureFlippingStore(documentAPI, true);

      let response = await this.FeatureFlippingStore.Get("Print");

      store.commit("featuresFlipping/printAuthorise", response === 0 ? false : true);
    } catch (error) {
      Vue.$log.error(error);
    }
  }
};

export default new featuresFlippingService();
