import store from "@/app/app-state";

const documentRoutes = [
  {
    name: "Document.Web",
    path: "/Document/Document",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Document",
        },
        {
          text: "Document",
          to: "/Document/Document",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "documentComponents" */ "./document/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Document.Web",
    path: "/File/:guid",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Fichier",
          to: "/Fichier",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "documentComponents" */ "./file/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Document.Web",
    path: "/Document/Printing",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Document",
        },
        {
          text: "Impression",
          to: "/Document/Printing",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "orderComponents" */ "./printing/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Document.Web",
    path: "/Document/Printer",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Document",
        },
        {
          text: "Imprimante",
          to: "/Document/Printer",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "orderComponents" */ "./printer/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
];

export default documentRoutes;
