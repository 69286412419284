import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

import userContext from "./shared/state/userContextState";
import urlApi from "./shared/state/urlApiState";
import showNotifications from "./shared/state/showNotificationsState";
import featuresFlipping from "./shared/state/featuresFlippingState";

export default new Vuex.Store({
  modules: {
    userContext,
    urlApi,
    showNotifications,
    featuresFlipping,
  },
});
