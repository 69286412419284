const HomeRoutes = [
  {
    path: "/Home",
    component: () => import(/* webpackChunkName: "homeComponents" */ "./home/Home.vue"),
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Accueil",
          to: "/Home",
        },
      ],
    },
  },
  {
    path: "/Dashboard",
    component: () => import(/* webpackChunkName: "homeComponents" */ "./dashboard/Dashboard.vue"),
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Tableau de bord",
          to: "/Dashboard",
        },
      ],
    },
  },
  {
    path: "/Privacy",
    component: () => import(/* webpackChunkName: "homeComponents" */ "./privacy/Privacy.vue"),
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Politique de confidentialité",
          to: "/Privacy",
        },
      ],
    },
  },
  {
    path: "/Contact",
    component: () => import(/* webpackChunkName: "homeComponents" */ "./contact/Contact.vue"),
    meta: {
      allowAnonymous: true,
      breadcrumb: [
        {
          text: "Aide",
        },
        {
          text: "Nous contacter",
          to: "/Privacy",
        },
      ],
    },
  },
  {
    path: "/Profile",
    component: () => import(/* webpackChunkName: "homeComponents" */ "./profile/Profile.vue"),
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Mon profil",
          to: "/Profile",
        },
      ],
    },
  },
];

export default HomeRoutes;
