var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("footer", { staticClass: "footer border-top mt-auto py-2 px-3" }, [
      _c("div", { staticClass: "container" }, [
        _c("div", { staticClass: "row" }, [
          _c("div", { staticClass: "col-6" }, [
            _vm._v(" Copyright © " + _vm._s(_vm.year) + " - "),
            _c(
              "a",
              { attrs: { href: "http://www.tesfri.fr", target: "_blank" } },
              [_vm._v("INNLOG")]
            ),
          ]),
          _c(
            "div",
            { staticClass: "col-6 text-right" },
            [
              _c("router-link", { attrs: { to: "/Privacy" } }, [
                _vm._v("Politique de confidentialité"),
              ]),
            ],
            1
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }