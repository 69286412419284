// import Vue from "vue";
// import axios from "axios";

const urlApi = {
  namespaced: true,
  state: {
    urls: [],
  },
  mutations: {
    urls(state, newState) {
      state.urls = newState;
    },
  },
  actions: {
    updateUrlApi({ commit, state }, newUrl) {
      return new Promise((resolve, reject) => {
        if (newUrl) {
          commit("urlApi", newUrl);
          resolve(state.urlApi);
        } else {
          reject();
        }
      });
    },
  },
};

export default urlApi;
