import RestClient from "@/app/shared/services/helpers/RestClient";

class FeatureFlippingStore {
  constructor(baseUrl, showNotification) {
    this.loading = false;
    this.client = new RestClient(baseUrl);
    this.restClient = new RestClient(baseUrl).Instance;
    this.showNotification = showNotification;
    this.baseUrl = baseUrl;
  }

  /*FeatureFlipping*/
  async Get(code) {
    this.loading = true;
    let response = await this.restClient.get("FeatureFlippings/" + code);
    let data = response.data;

    this.loading = false;

    return data;
  }
}

export default FeatureFlippingStore;
