import Vue from "vue";
//import axios from "axios";
import store from "@/app/app-state";

const refreshNotifications = class {
  async start() {
    await this.setNotifications();
  }
  refreshNotifications() {
    if (Vue.prototype.$keycloak.authenticated) {
      Vue.$log.debug("refreshNotifications");

      // todo => implémenter l'API User + decommenter
      // axios
      //   .get("https://user-api.com/Sites", {
      //     headers: [{ Authorization: "Bearer " + Vue.prototype.$keycloak.token }]
      //   })
      //   .then(response => {
      //     if (response.status == 200) {
      //       Vue.$log.debug("NewSites from UserApi: " + response.data.Sites);
      //       store.dispatch("userContext/updateSites", response.data.Sites);
      //     }
      //   })
      //   .catch(error => {
      //     Vue.$log.error(error);
      //   });
    }
  }

  async setNotifications() {
    if (Vue.prototype.$keycloak.authenticated) {
      store.commit("showNotifications/showError", true);
      store.commit("showNotifications/showSucces", false);
      store.commit("showNotifications/showWarning", false);
      store.commit("showNotifications/showInfo", false);
    }
  }
};

export default new refreshNotifications();
