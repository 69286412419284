import Vue from "vue";
//import axios from "axios";
import store from "@/app/app-state";
import InfrastructureConfiguration from "../../shared/services/infrastructureConfiguration";
import { apiConfiguration } from "@/environment/environment";

const refreshUrlApi = class {
  async start() {
    await this.setUrls();
  }

  refreshUrlApi() {
    if (Vue.prototype.$keycloak.authenticated) {
      Vue.$log.debug("refreshUrlApi");

      // todo => implémenter l'API User + decommenter
      // axios
      //   .get("https://user-api.com/Sites", {
      //     headers: [{ Authorization: "Bearer " + Vue.prototype.$keycloak.token }]
      //   })
      //   .then(response => {
      //     if (response.status == 200) {
      //       Vue.$log.debug("NewSites from UserApi: " + response.data.Sites);
      //       store.dispatch("userContext/updateSites", response.data.Sites);
      //     }
      //   })
      //   .catch(error => {
      //     Vue.$log.error(error);
      //   });
    }
  }

  async setUrls() {
    if (Vue.prototype.$keycloak.authenticated) {
      this.InfrastructureConfiguration = new InfrastructureConfiguration(apiConfiguration);
      let ApiNames = ["Activity", "Delivery", "Document", "Log", "Order", "Repository", "Stock", "User"];
      let urlsApi = {};
      urlsApi["Configuration"] = apiConfiguration;
      for (let apiName of ApiNames) {
        urlsApi[apiName] = await this.InfrastructureConfiguration.getConfiguration(apiName + ":ApiUrl");
      }
      store.commit("urlApi/urls", urlsApi);
    }
  }
};

export default new refreshUrlApi();
