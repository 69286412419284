import "material-design-icons-iconfont/dist/material-design-icons.css"; // Ensure you are using css-loader
import Vue from "vue";
import VueLogger from "vuejs-logger";
//import './plugins';

import VueKeycloakJs from "@innlog/keycloak-vue";

//impression code à bare
import VueBarcode from "@chenfengyuan/vue-barcode";
Vue.component(VueBarcode.name, VueBarcode);

// Vue de base
import App from "./app/App.vue";

// Bootstrap
import BootstrapVue from "bootstrap-vue";
Vue.use(BootstrapVue);
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import "popper.js/dist/popper.js";

// JQuery
import JQuery from "jquery";
window.$ = window.jQuery = JQuery;

// Fontawesome
import "@fortawesome/fontawesome-free/css/all.css";
import "@fortawesome/fontawesome-free/js/all.js";

// Vue Signature Pad
import VueSignature from "vue-signature-pad";
Vue.use(VueSignature);

import "./main.scss";

import router from "./app/app-routes";
import store from "./app/app-state";
import services from "./app/app-services";

import { keycloakUrl, keycloakClientId, keycloakRealm } from "@/environment/environment";

Vue.config.productionTip = false;

const options = {
  isEnabled: true,
  logLevel: Vue.config.productionTip ? "error" : "debug",
  stringifyArguments: false,
  showLogLevel: true,
  showMethodName: true,
  separator: "|",
  showConsoleColors: true,
};
Vue.use(VueLogger, options);

// VeeValidate
import { extend, localize } from "vee-validate";
import * as rules from "vee-validate/dist/rules";
// loop over all rules
for (let rule in rules) {
  // add the rule
  extend(rule, rules[rule]);
}
// add custom rules
import { addCustomRules } from "./app/shared/js/custom-validator";
addCustomRules();

import fr from "vee-validate/dist/locale/fr.json";
localize("fr", fr);

// Vuetify
import Vuetify from "vuetify";
import "vuetify/dist/vuetify.min.css";
const opts = {
  icons: {
    iconfont: "md",
  },
  theme: {
    dark: false,
    themes: {
      light: {
        next: "#ec406f",
        darkNext: "#c13660",
      },
    },
  },
};
Vue.use(Vuetify);

/* Fontawesome */
import { library } from "@fortawesome/fontawesome-svg-core";
import { faUserSecret } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
library.add(faUserSecret);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

Vue.use(VueKeycloakJs, {
  init: {
    onLoad: "check-sso",
    flow: "hybrid",
    promiseType: "native",
  },
  config: {
    realm: keycloakRealm,
    url: keycloakUrl,
    clientId: keycloakClientId,
  },
  onReady: async () => {
    await services.start();

    new Vue({
      router,
      store,
      vuetify: new Vuetify(opts),
      render: (h) => h(App),
    }).$mount("#app");
  },
});
