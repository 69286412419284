import store from "@/app/app-state";

const repositoryRoutes = [
  {
    path: "/repository",
    redirect: "/repository/product",
  },
  {
    name: "Repository.Web",
    path: "/repository/product",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Paramétrage",
        },
        {
          text: "Référentiel produit",
          to: "/repository/product",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "repositoryComponents" */ "./product/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Repository.Web",
    path: "/repository/productFamily",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Paramétrage",
        },
        {
          text: "Famille de produit",
          to: "/repository/productFamily",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "repositoryComponents" */ "./productFamily/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Repository.Web",
    path: "/repository/company",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Paramétrage",
        },
        {
          text: "Référentiel tiers",
          to: "/repository/company",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "repositoryComponents" */ "./company/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Repository.Web",
    path: "/repository/attributes",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Paramétrage",
        },
        {
          text: "Attributs",
          to: "/repository/attributes",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "repositoryComponents" */ "./attributes/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Repository.Web",
    path: "/repository/groupAttributes",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Paramétrage",
        },
        {
          text: "Groupes d'attributs",
          to: "/repository/groupsAttributes",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "repositoryComponents" */ "./groupsAttributes/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Repository.Web",
    path: "/repository/locationTypes",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Paramétrage",
        },
        {
          text: "Type d'emplacement",
          to: "/repository/locationTypes",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "repositoryComponents" */ "./locationTypes/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Repository.Web",
    path: "/repository/supportTypes",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Paramétrage",
        },
        {
          text: "Type de support",
          to: "/repository/supportTypes",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "repositoryComponents" */ "./supportTypes/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Repository.Web",
    path: "/repository/unit",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Paramétrage",
        },
        {
          text: "Unité",
          to: "/repository/unit",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "repositoryComponents" */ "./unit/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Repository.Web",
    path: "/repository/valueTypes",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Paramétrage",
        },
        {
          text: "Type de valeurs",
          to: "/repository/valueTypes",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "repositoryComponents" */ "./valueTypes/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
];

export default repositoryRoutes;
