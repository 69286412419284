import Vue from "vue";
import store from "@/app/app-state";
import RestClient from "./helpers/RestClient";
import { urlToCustomer } from "@/environment/environment";

const userContextService = class {
  async start() {
    let result = await this._setUser();

    if (!result) {
      return;
    }

    this._setCustomers();
    this.setCurrentCustomer();
    await this._refreshUserTenants();
    setInterval(() => this._refreshUserTenants(), 900000);
    this._setCurrentTenant();
    await this._refreshUserRights();
    setInterval(() => this._refreshUserRights(), 900000);
  }

  async _setUser() {
    if (Vue.prototype.$keycloak.authenticated) {
      store.commit("userContext/fullName", Vue.prototype.$keycloak.fullName);
      store.commit("userContext/user", Vue.prototype.$keycloak.idTokenParsed.sub);

      let userAPI = store.state.urlApi.urls.User;
      this.client = new RestClient(userAPI);
      this.restClient = new RestClient(userAPI).Instance;
      this.restClient.handleError = false;
      let response = await this.restClient.get(`/Users/ByGuid/${Vue.prototype.$keycloak.idTokenParsed.sub}`);

      if (response.status >= 400) {
        return false;
      }

      let nextUser = response.data;

      store.commit("userContext/nextUser", nextUser);

      return true;
    }

    return false;
  }

  _setCustomers() {
    if (Vue.prototype.$keycloak.authenticated) {
      let customers = Vue.prototype.$keycloak.tokenParsed.next_stockage_customers_codes;
      store.commit("userContext/customers", customers);
    }
  }

  setCurrentCustomer() {
    if (Vue.prototype.$keycloak.authenticated) {
      let currentUrlCustomer = window.location.host.split(".")[0].split(":")[0];
      store.commit("userContext/currentCustomer", urlToCustomer[currentUrlCustomer.toLowerCase()]);
    }
  }

  async _refreshUserTenants() {
    if (Vue.prototype.$keycloak.authenticated) {
      try {
        let userAPI = store.state.urlApi.urls.User;
        this.client = new RestClient(userAPI);
        this.restClient = new RestClient(userAPI).Instance;
        this.restClient.handleError = false;
        let response = await this.restClient.get("Tenants/Authorized");

        if (response.status === 401 || response.status === 403) {
          await this._refreshForAuthenticate();
        }

        store.commit("userContext/tenants", response.data);
      } catch (error) {
        Vue.$log.error(error);
      }
    } else {
      await this._refreshForAuthenticate();
    }
  }

  _setCurrentTenant() {
    if (Vue.prototype.$keycloak.authenticated) {
      // Get first tenant
      // TODO -> Change this !
      store.commit("userContext/currentTenant", store.state.userContext.tenants[0].code);
    }
  }

  async _refreshUserRights() {
    if (Vue.prototype.$keycloak.authenticated) {
      try {
        let tenantCode = store.state.userContext.currentTenant;

        let userAPI = store.state.urlApi.urls.User;
        this.client = new RestClient(userAPI);
        this.restClient = new RestClient(userAPI).Instance;

        //Chargement des modules en fonction du tenant
        let responseModules = await this.restClient.get("/Modules?tenantCode=" + tenantCode);
        let modules = responseModules.data;

        //Profils liés au module, sur le currentTenant pour l'utilisateur
        let responseUser = await this.restClient.get("/Users/ByGuid/" + Vue.prototype.$keycloak.tokenParsed.sub);
        let user = responseUser.data;

        let rights = {};
        for (let module of modules) {
          let profileTenant =
            user.profileTenants[
              user.profileTenants.findIndex(
                (p) => (p.moduleId === module.id) & (p.tenantId === store.state.userContext.tenants[0].id) /*id du tenant*/
              )
            ];
          if (profileTenant != null) {
            rights[module.code] = [];
          }
        }
        store.dispatch("userContext/updateRights", rights);
      } catch (error) {
        //Log
        Vue.$log.error(error);
      }
    }
  }

  async _refreshForAuthenticate() {
    const redirectUri = window.location.href;
    let loginUrl = Vue.prototype.$keycloak.createLoginUrl({
      postLogoutRedirectUri: redirectUri,
      idTokenHint: Vue.prototype.$keycloak.idToken,
    });
    window.location.replace(loginUrl);
  }
};

export default new userContextService();
