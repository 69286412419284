/**
 * Subscription Notification Push  class
 * @description This will be responsible  for registering the subscription for a user
 */

import RestClient from "./helpers/RestClient";
import Notification from "./helpers/Notification";

class SubscriptionNotificationPush {
  constructor(baseUrl, showNotification) {
    this.loading = false;
    this.showNotification = showNotification;
    this.client = new RestClient(baseUrl);
    this.restClient = new RestClient(baseUrl).Instance;
  }

  async subscriptions(data) {
    this.loading = true;
    let response = await this.restClient.post("Subscriptions", data);

    if (response.status >= 400 && response.status < 500) {
      let errorMessage;
      let title = response.data.title;

      if (response.data.errors) {
        Object.keys(response.data.errors).forEach(function (k) {
          let errorTitle = k;
          let errorList = "";

          response.data.errors[k].forEach(function (element) {
            errorList += element;
          });

          errorMessage += `${errorTitle} : ${errorList}`;
        });

        if (this.showNotification.ShowError) Notification.notifyError(`${title}`, `${errorMessage}`);
        console.log(`${title} ${errorMessage}`);
      } else {
        if (this.showNotification.ShowError)
          Notification.notifyError(`Une erreur est survenue pendant la souscription aux notifications push `, `${response.statusText}`);
        console.log(`Remote Connection ${response.statusText}`);
      }
    } else {
      if (this.showNotification.ShowSucces) Notification.notifySuccess(`Subscription API ok / result`, `Success`);
      console.log(`Subscription API ok`);
    }

    this.loading = false;

    return {
      response,
    };
  }
}

export default SubscriptionNotificationPush;
