class ScreenHelper {
  constructor() {
    this.tabletWidth = 768;

    this.screenWidth = screen.width;
    this.screenHeight = screen.height;

    this.isMobile = this.screenWidth <= this.tabletWidth;
  }
}

export default new ScreenHelper();
