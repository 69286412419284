import store from "@/app/app-state";

const stockRoutes = [
  {
    path: "/stock",
    redirect: "/stock/stock",
  },
  {
    name: "Stock.Web",
    path: "/stock/preparation",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Entreposage",
        },
        {
          text: "Préparation de commande",
          to: "/stock/preparation",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "stockComponents" */ "./preparation/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Stock.Web",
    path: "/stock/preparationMobile",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Entreposage",
        },
        {
          text: "Préparation de commande",
          to: "/stock/preparationMobile",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "stockComponents" */ "./preparation/appMobile.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Stock.Web",
    path: "/stock/preparation/:id",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Entreposage",
        },
        {
          text: "Préparation",
          to: "/stock/preparation",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "stockComponents" */ "./preparation/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Stock.Web",
    path: "/stock/preparationMobile/:id",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Entreposage",
        },
        {
          text: "Préparation de commande",
          to: "/stock/preparationMobile",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "stockComponents" */ "./preparation/appMobile.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Stock.Web",
    path: "/stock/reception",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Entreposage",
        },
        {
          text: "Réception de marchandises",
          to: "/stock/reception",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "stockComponents" */ "./reception/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Stock.Web",
    path: "/stock/receptionMobile",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Entreposage",
        },
        {
          text: "Réception de marchandises",
          to: "/stock/receptionMobile",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "stockComponents" */ "./reception/appMobile.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Stock.Web",
    path: "/stock/addressTransfert",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Entreposage",
        },
        {
          text: "Transfert d'adresse",
          to: "/stock/addressTransfert",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "stockComponents" */ "./addressTransfert/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Stock.Web",
    path: "/stock/regularisation",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Entreposage",
        },
        {
          text: "Correction de stock",
          to: "/stock/regularisation",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "stockComponents" */ "./regularisation/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Inventory",
    path: "/stock/inventory",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Entreposage",
        },
        {
          text: "Inventaire",
          to: "/stock/inventory",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "stockComponents" */ "./inventory/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Stock.Web",
    path: "/stock/stock",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Entreposage",
        },
        {
          text: "Gestion du stock",
          to: "/stock/stock",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "stockComponents" */ "./stock/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Stock.Web",
    path: "/stock/location",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Entreposage",
        },
        {
          text: "Emplacement",
          to: "/stock/location",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "stockComponents" */ "./location/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
  {
    name: "Stock.Web",
    path: "/stock/location2",
    meta: {
      allowAnonymous: false,
      breadcrumb: [
        {
          text: "Entreposage",
        },
        {
          text: "Supervision",
          to: "/stock/location2",
        },
      ],
    },
    component: () => import(/* webpackChunkName: "stockComponents" */ "./location2/app.vue"),
    beforeEnter: (to, _from, next) => {
      if (to.matched.some((record) => record.meta.allowAnonymous)) {
        next();
      } else {
        if (typeof to.meta != "undefined") {
          if (store.state.userContext.rights[to.name]) {
            next();
          } else {
            next("/Unauthorized", { replace: true, name: "page-unauthorized" });
          }
        } else {
          next("/Unauthorized", { replace: true, name: "page-unauthorized" });
        }
      }
    },
  },
];

export default stockRoutes;
