<template>
  <div>
    <footer class="footer border-top mt-auto py-2 px-3">
      <div class="container">
        <div class="row">
          <div class="col-6">
            Copyright &copy; {{ year }} -
            <a href="http://www.tesfri.fr" target="_blank">INNLOG</a>
          </div>
          <div class="col-6 text-right">
            <router-link to="/Privacy">Politique de confidentialité</router-link>
          </div>
        </div>
      </div>
    </footer>
  </div>
</template>

<script>
export default {
  name: "app-footer",

  data() {
    return {
      year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped>
@import "app-footer.scss";
</style>
